import React from 'react'
import Layout from '../layouts/layout'
import { input, section } from '../styles/styles'

import { formInputs } from '../content/inscription'
import RenderInput from '../hooks/renderInput'
// https://codesandbox.io/s/gatsby-crud-tutorial-c6xs1?file=/src/pages/index.js
// https://dev.to/vetswhocode/build-a-crud-firestore-app-in-react-gatsby-with-hooks-4ig9

const inscription = () => {
    return (
        <Layout>
            <section className={`${section} lg:pt-10 border `}>
                <div className="px-4 mx-auto wrapper-lg sm:px-6 lg:px-8 pb-10">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 ">
                        <div className="max-w-2xl mx-auto text-center">
                            <h1 className="title sm:text-4xl lg:text-5xl">
                                Inscription
                            </h1>
                            <p className='text-2xl dark mt-2'>Veuillez remplir tous les champs obligatoires  </p>
                            <p className='text-xl dark mt-2 text-red-600'>Les inscriptions sont fermées.</p>
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden ">
                    <div className="px-0 sm:pb-20 sm:px-12 sm:w-full lg:w-[95%] mx-auto">
                        <RenderInput data={formInputs} />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default inscription