import React, { useState } from 'react'
import firebase from '../firebase'
import { getDocs, collection, addDoc, doc, query, where, limit } from 'firebase/firestore'
import { db } from "../firebase";

import FormField from '../components/form-field'
import FormSelect from '../components/form-select'
import Message from '../components/message'

import { input as cssClass, btnPrimary } from '../styles/styles'

import { formInputs, getInitialState, columns } from '../content/inscription'
import useForm from './useForm'
import { checkInputs } from './useForm/validation'

const RenderInput = ({ data }) => {
    const [formState, setFormState] = useState(getInitialState())
    const [showMessage, setShowMessage] = useState(false)

    const { values, errors, handleChange, formSubmit, isValid } = useForm(
        handleSubmit,
        // if new record initilState ? the fetched data
        formState
    )
    const [errorsForm, setErrorsForm] = useState(errors)

    const validateInputs = () => {
        const requiredInputs = columns.filter((col) => col.rule).map((item) => item.name)
        const inputs = requiredInputs.reduce(
            (acc, curr) => ((acc[curr] = values[curr]), acc),
            {}
        )
        return inputs
    }

    function toLowerKeys(obj) {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, value.toLowerCase()])
        );
    }


    const addInscription = async () => {
        const usersCollectionRef = collection(db, "camptafraout-23");
        try {
            const createdAt = firebase.firestore.FieldValue.serverTimestamp();

            const louer = values.louer ? 'oui' : 'non'
            const transfert = values.transfert ? 'oui' : 'non'
            //const newValues = toLowerKeys(values)
            //console.log(newValues);
            //return
            const response = await addDoc(usersCollectionRef, {
                ...values, nom: values.nom.toLowerCase(), louer, transfert, createdAt
            });
            setShowMessage(true)
        } catch (error) {
            console.log(error.message);
        }
    };


    const checkUserExist = async () => {
        const usersCollectionRef = collection(db, "camptafraout-23");
        const querySnapshot = await getDocs(query(usersCollectionRef,
            where("nom", "==", values.nom.trim().toLowerCase()),
            limit(1)
        ));

        return !querySnapshot.empty
    }

    async function handleSubmit() {
        return
        const errs = checkInputs(validateInputs())
        setErrorsForm(errs)
        if (Object.keys(errs).length > 0) return
        const isUserExist = await checkUserExist()
        //console.log('exist', isUserExist);
        if (isUserExist) {
            setErrorsForm({ ...errorsForm, nom: isUserExist ? 'Ce nom déjà enregistré!' : null })
            return
        }
        await addInscription()


        //Check for the meail if is already subscribed
    }

    const check = async () => {
        const isExist = await checkUserExist()
    }
    React.useEffect(() => {
        setErrorsForm(errors)
    }, [errors])



    return (
        <>
            {showMessage ? <Message /> : (
                <div className='border bg-white px-4 py-4 sm:px-12 sm:py-8'>
                    <form method="POST" className="mt-0 sm:mt-14 bg-red-100" onSubmit={formSubmit}>

                        {/*  <button onClick={addInscription} className={btnPrimary}>Check</button>  */} 

                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-4">
                    {
                        data.map((input) => {
                            const { label, name, type, placeholder, validationRule } = input

                            return (type !== 'select' && type !== 'checkbox') ? (
                                <div key={name}>
                                    <div className="mt-2.5 relative">
                                        <FormField
                                            label={label}
                                            type={type}
                                            name={name}
                                            key={name}
                                            classname={cssClass}
                                            placeholder={placeholder}
                                            errorMessage={errorsForm[name]}
                                            validationRule={validationRule}
                                            value={values ? values[name] : ''}
                                            handleChange={handleChange}
                                            required={validationRule ? true : false}
                                        />
                                    </div>
                                </div>
                            ) : type === 'select' ? (
                                    <div key={name}>
                                    <div className="mt-2.5 relative">
                                            <FormSelect
                                                classname={cssClass}
                                                label={label}
                                                key={name}
                                                name={name}
                                                options={input.options}
                                                value={values ? values[name] : ''}
                                                handleChange={handleChange}
                                                validationRule={validationRule}
                                                errorMessage={errorsForm[name]}
                                                required={validationRule ? true : false}
                                        />
                                    </div>
                                </div>
                            ) : (
                                        <div className='sm:mt-5'>
                                            <div className={`sm:mt-2.5 relative  rounded-md py-3 px-4 border ${errorsForm[name]} && 'border border-red-600'}`}>

                                                <input type="checkbox" id="checkbox" className='mr-4 border' style={{ marginRight: '5px' }} 
                                                    name={name}
                                                    checked={values ? values[name] : ''}
                                                    onChange={handleChange} 
                                                    data-validate={validationRule}
                                                   /*  required={validationRule ? true : false} */
                                                />

                                                {label.includes(';') ? (
                                                    <>
                                                        <label htmlFor="checkbox">
                                                            {label.split(';')[0]}; <a href='/reglement' target="_blank" className='ml-1 text-blue-600 underline-offset-1'>{label.split(';')[1]}</a>
                                                            <span className=" ml-1 after:content-['*'] after:ml-0.5 after:text-red-500"></span>
                                                        </label>

                                                        {errorsForm[name] && <p className="text-red-800  mt-0 pt-1 text-base">{errorsForm[name]}</p>}
                                                    </>
                                                ) : (
                                                    <label htmlFor="checkbox">
                                                        {label}
                                                    </label>
                                                )
                                                } 
                                    </div>
                                </div>
                            )
                        })
                    }
                            <div className="mt-4">
                        <button type="submit" className={`${btnPrimary} sm:w-full rounded-md py-0 sm:py-1`}
                            disabled={!isValid}
                        >
                            Inscription
                        </button>
                    </div>
                </div>
                    </form >
                </div >
            )
            }
        </>
    )
}

export default RenderInput